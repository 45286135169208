import React from 'react'

import Layout from '../../components/Layout'
import ProductsRoll from '../../components/ProductsRoll'

import { QBP_BLUE_A50 } from '../../const'

export default class ProductsIndexPage extends React.Component {
    render() {
        return (
            <Layout>
                <div className="header-container" style={{ position: 'relative', backgroundColor: 'black' }}>
                    <div
                        className="full-width-image-container margin-top-0"
                        style={{
                            backgroundImage: `url('/img/products-header.jpg')`,
                            filter: 'blur(0.9px)',
                            backgroundPosition: 'top',
                            opacity: 0.6
                        }}
                    />
                    <h1
                        className="has-text-weight-bold is-size-1"
                        style={{
                            position: 'absolute',
                            boxShadow: `0.5rem 0 0 ${QBP_BLUE_A50}, -0.5rem 0 0 ${QBP_BLUE_A50}`,
                            backgroundColor: QBP_BLUE_A50,
                            color: 'white',
                            padding: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        Products
                    </h1>
                </div>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <ProductsRoll />
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
